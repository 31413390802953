import { defineComponent as _defineComponent } from 'vue'
import { logger as $log } from "@Plugins/clientLogger"
import { useHead } from "@unhead/vue"
import { getStaticPageContent } from "@website/api/app"
import startCase from "lodash-es/startCase"
import { computed, nextTick, ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"


export default /*@__PURE__*/_defineComponent({
  __name: 'content.page',
  props: {
    pageName: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props
const route = useRoute()
const router = useRouter()
const html = ref("")
const previousPageName = ref("")
const formattedTitle = computed(() => startCase(props.pageName))
const canonicalURL = computed(
	() => "https://gnomebrewshop.com/" + props.pageName,
)
useHead({
	title: () => formattedTitle.value,
	link: [{ rel: "canonical", href: canonicalURL }],
})

const loadPageContent = async (): Promise<void> => {
	if (props.pageName === previousPageName.value) return
	previousPageName.value = props.pageName
	const contentContainer = document.getElementById("content-container")
	contentContainer?.scrollTo({ top: 0, behavior: "smooth" })
	//contentContainer?.scrollTo(0, 0)
	const fullPath = route.fullPath
	//Save anchor hash
	const hash = fullPath.split("#")[1]
	try {
		const { status, html: fetchedHTML } = await getStaticPageContent(
			props.pageName,
		)
		if (status === 404) {
			$log.info(
				"app/components/content 404",
				{ path: route.fullPath },
				{ query: route.query },
				{ params: route.params },
			)
			await router.replace("/404")
		} else {
			html.value = fetchedHTML
			await nextTick()
			if (hash) {
				const element = document.getElementById(hash)
				if (element) element.scrollIntoView()
			}
		}
	} catch (error) {
		if (
			["server_error", "response_error", "unknown_error"].includes(
				error.message,
			)
		) {
			alert(
				"Server Error--Please Try Again Later.  If You Receive This Message Again, Please Contact Us at gnomes@gnomebrewshop.com or 773-961-7750.  Sorry For The Inconvenience.",
			)
		} else if (error.message == "request_error") {
			alert(
				"There Appears To Be A Problem With The Network.  Please Try Again.",
			)
		}
	}
}
watch(
	() => props.pageName,
	async () => {
		await loadPageContent()
	},
	{ immediate: true },
)

const __returned__ = { props, route, router, html, previousPageName, formattedTitle, canonicalURL, loadPageContent, get $log() { return $log }, get useHead() { return useHead }, get getStaticPageContent() { return getStaticPageContent }, get startCase() { return startCase }, computed, nextTick, ref, watch, get useRoute() { return useRoute }, get useRouter() { return useRouter } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})