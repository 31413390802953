import { defineComponent as _defineComponent } from 'vue'
import { preventInvalidDecimalKey } from "@Functions/fnInput"
import { fnConvertOunces } from "@Functions/fnShop"
import { logger as $log } from "@Plugins/clientLogger"
import { useModal } from "@shared/plugins/gbModal/gbModal"
import { useRecipeBuilderStore } from "@website/stores/recipeBuilder"
import { debounce } from "lodash"
import { computed, onMounted, ref, watch } from "vue"

//types
import type { IShopCartItem } from "types/ShopCartItem"


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeCart_Item',
  props: {
    item: {},
    editmode: { type: Boolean, default: false },
    recipeID: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props

const recipeBuilderStore = useRecipeBuilderStore()

const image = computed((): string => {
	if (props.item.photos?.v50px) {
		return (
			"https://gnomebrew-cdn.s3.us-east-2.amazonaws.com/images/products/" +
			props.item.photos.v50px
		)
	} else {
		return "https://gnomebrew-cdn.s3.us-east-2.amazonaws.com/images/products/gnome-thumb.jpg"
	}
})

const inputQuantity = ref(0)

const debouncedUpdateItemQuantity = (): void => {
	const { success, updatedCartQuantity, errorMessage } =
		recipeBuilderStore.updateWorkingRecipe(
			props.item.sku,
			inputQuantity.value,
			undefined,
			"Recipe Cart",
		)
	inputQuantity.value = updatedCartQuantity
	if (success === false) useModal.show("gbDialog", { title: errorMessage })
}

const updateItemQuantity = (): void => {
	debounce(debouncedUpdateItemQuantity, 500)()
}

const removeItemInRecipe = (): void => {
	recipeBuilderStore.removeItemFromWorkingRecipe(
		props.item.sku,
		"Recipe Cart",
	)
}

const convertOunces = (event: Event): void => {
	if (event.target instanceof HTMLInputElement) {
		const input = event.target.value
		const pounds = fnConvertOunces(input)
		event.target.value = pounds.toString()
	} else {
		$log.error({ event }, "Event Target is not an Input Element")
	}
}

onMounted(() => {
	inputQuantity.value = props.item.quantity
})

watch(
	() => props.item.quantity,
	() => {
		inputQuantity.value = props.item.quantity
	},
	{ immediate: true },
)

const __returned__ = { props, recipeBuilderStore, image, inputQuantity, debouncedUpdateItemQuantity, updateItemQuantity, removeItemInRecipe, convertOunces, get preventInvalidDecimalKey() { return preventInvalidDecimalKey }, get fnConvertOunces() { return fnConvertOunces }, get $log() { return $log }, get useModal() { return useModal }, get useRecipeBuilderStore() { return useRecipeBuilderStore }, get debounce() { return debounce }, computed, onMounted, ref, watch }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})