import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "cart-item" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "cart-item-infobox" }
const _hoisted_4 = { class: "cart-item-infobox-row" }
const _hoisted_5 = { class: "cart-item-infobox-price" }
const _hoisted_6 = { class: "cart-item-infobox-row" }
const _hoisted_7 = { class: "cart-item-infobox-quantity" }
const _hoisted_8 = ["max", "step"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "cart-item-infobox-subtotal" }
const _hoisted_11 = {
  key: 0,
  class: "cart-item-infobox-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: "cart-item-imagebox",
      to: $setup.href,
      onClick: $setup.closeHeaderCart
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          class: "button-icon",
          src: $setup.productThumb
        }, null, 8 /* PROPS */, _hoisted_2)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["to"]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_link, {
          class: "nodecoration cart-item-infobox-name",
          to: $setup.href,
          onClick: $setup.closeHeaderCart
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.item.name), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["to"]),
        _createElementVNode("div", _hoisted_5, "$" + _toDisplayString($props.item.price.toFixed(2)), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          ($props.editmode)
            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 0,
                type: "number",
                min: "0",
                max: $setup.quantityAvailable,
                step: $props.item.bulk ? '0.01' : '1',
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.quantity) = $event)),
                onKeydown: _cache[1] || (_cache[1] = ($event: any) => ($setup.preventInvalidDecimalKey($event)))
              }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_8)), [
                [
                  _vModelText,
                  $setup.quantity,
                  void 0,
                  { number: true }
                ]
              ])
            : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString($setup.quantity), 1 /* TEXT */))
        ]),
        _createElementVNode("div", _hoisted_10, "$" + _toDisplayString(($props.item.price * $setup.quantity).toFixed(2)), 1 /* TEXT */),
        ($props.editmode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", {
                class: "button-icon",
                onClick: _withModifiers($setup.removeCartItem, ["stop"]),
                tabindex: "-1",
                title: "Remove Item From Cart"
              }, _cache[2] || (_cache[2] = [
                _createElementVNode("svg", {
                  xmlns: "http://www.w3.org/2000/svg",
                  height: "40",
                  viewBox: "0 96 960 960",
                  width: "40"
                }, [
                  _createElementVNode("path", { d: "M298.615 896q-23.91 0-41.186-17.275-17.275-17.276-17.275-41.186v-510.77H200v-33.846h156.102V265.23h247.796v27.693H760v33.846h-40.154v510.77q0 24.577-16.942 41.519Q685.962 896 661.385 896h-362.77ZM686 326.769H274v510.77q0 10.769 7.179 17.692 7.18 6.923 17.436 6.923h362.77q9.231 0 16.923-7.692Q686 846.77 686 837.539v-510.77ZM394.41 780.205h33.846V408.051H394.41v372.154Zm137.334 0h33.846V408.051h-33.846v372.154ZM274 326.769v535.385-535.385Z" })
                ], -1 /* HOISTED */)
              ]))
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ]))
}