import { defineComponent as _defineComponent } from 'vue'
import { preventInvalidDecimalKey } from "@Functions/fnInput"
import { fnConvertOunces } from "@Functions/fnShop"
import { logger as $log } from "@Plugins/clientLogger"
import { useModal } from "@shared/plugins/gbModal/gbModal"
import { useOrderStore } from "@website/stores/order.js"
import { useRecipeBuilderStore } from "@website/stores/recipeBuilder"
import { computed, ref, toValue, watch } from "vue"

import type { IPublicInventoryItem } from "types/InventoryItem"
import type { IRecipe } from "types/Recipe"
import type { IShopCartItem } from "types/ShopCartItem"


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeBuilder_Item',
  props: {
    item: {},
    recipe: {},
    resultRank: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props
const orderStore = useOrderStore()
const recipeBuilderStore = useRecipeBuilderStore()

const image = computed((): string => {
	if (props.item?.photos?.v250px) {
		return (
			"https://gnomebrew-cdn.s3.us-east-2.amazonaws.com/images/products/" +
			props.item.photos.v250px
		)
	} else {
		return "https://gnomebrew-cdn.s3.us-east-2.amazonaws.com/images/products/gnome-thumb.jpg"
	}
})

const inputQuantity = ref(0)

const cartQuantity = computed((): number | undefined => {
	let cartItem: IShopCartItem | undefined
	cartItem = props.recipe.items.find(
		(i: IShopCartItem) => i.sku === props.item.sku,
	)
	if (cartItem === undefined) return undefined
	return cartItem.quantity
})

const isSaved = ref(false)
const isUnsaved = ref(false)
const itemClass = computed((): object => {
	return {
		saved: isSaved.value,
		unsaved: isUnsaved.value,
	}
})
const updateSavedStatus = (): void => {
	if (cartQuantity.value === undefined) {
		isSaved.value = false
		isUnsaved.value = false
	} else if (inputQuantity.value != cartQuantity.value) {
		isSaved.value = false
		isUnsaved.value = true
	} else {
		isSaved.value = true
		isUnsaved.value = false
	}
}

const clickUpdateItem = (): void => {
	//If 'Add' Button is Clicked with No Quantity Assume 1
	const previousQuantity = toValue(cartQuantity) || 0
	if (previousQuantity === 0 && inputQuantity.value === 0)
		inputQuantity.value = 1
	updateItemQuantity()
}
const updateItemQuantity = (): void => {
	const { success, updatedCartQuantity, errorMessage } =
		recipeBuilderStore.updateWorkingRecipe(
			props.item.sku,
			inputQuantity.value,
			props.resultRank,
			"Recipe Builder",
		)
	inputQuantity.value = updatedCartQuantity
	if (success === false) {
		useModal.show("gbDialog", { title: errorMessage })
	}
	updateSavedStatus()
}

const convertOunces = (event: Event): void => {
	if (event.target instanceof HTMLInputElement) {
		const input = event.target.value
		const pounds = fnConvertOunces(input)
		inputQuantity.value = pounds
	} else {
		$log.error({ event }, "Event Target is not an Input Element")
	}
}

const isAvailable = computed((): boolean => {
	const quantityAvailable =
		props.item.inventory -
		orderStore.getQuantityOrderedElsewhere(props.item.sku, props.recipe.id)
	return quantityAvailable > 0
})

const addButtonText = computed((): string => {
	return cartQuantity.value ? "Update" : "Add"
})

watch(
	() => cartQuantity.value,
	() => {
		if (cartQuantity.value === undefined) inputQuantity.value = 0
		else inputQuantity.value = cartQuantity.value
		updateSavedStatus()
	},
	{ immediate: true },
)

const __returned__ = { props, orderStore, recipeBuilderStore, image, inputQuantity, cartQuantity, isSaved, isUnsaved, itemClass, updateSavedStatus, clickUpdateItem, updateItemQuantity, convertOunces, isAvailable, addButtonText, get preventInvalidDecimalKey() { return preventInvalidDecimalKey }, get fnConvertOunces() { return fnConvertOunces }, get $log() { return $log }, get useModal() { return useModal }, get useOrderStore() { return useOrderStore }, get useRecipeBuilderStore() { return useRecipeBuilderStore }, computed, ref, toValue, watch }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})