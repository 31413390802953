import { createElementVNode as _createElementVNode, withKeys as _withKeys, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "quickAdd" }
const _hoisted_2 = { id: "quickAddSearchWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("a", {
      id: "quickAddLabel",
      target: "_blank",
      href: "/about-recipe-builder#quickAdd",
      rel: "noopener noreferrer"
    }, [
      _createElementVNode("span", null, "Quick Add"),
      _createElementVNode("svg", {
        fill: "#000000",
        height: "24px",
        width: "24px",
        viewBox: "0 0 36 36",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }, [
        _createElementVNode("path", { d: "M17.962,24.725l1.806,0.096v2.531h-7.534v-2.406l1.045-0.094c0.568-0.063,0.916-0.254,0.916-1.014v-8.801 c0-0.699-0.188-0.92-0.791-0.92l-1.106-0.062v-2.626h5.666L17.962,24.725L17.962,24.725z M15.747,4.648 c1.394,0,2.405,1.047,2.405,2.374c0,1.331-1.014,2.313-2.438,2.313c-1.454,0-2.404-0.982-2.404-2.313 C13.31,5.695,14.26,4.648,15.747,4.648z M16,32C7.178,32,0,24.822,0,16S7.178,0,16,0c8.82,0,16,7.178,16,16S24.82,32,16,32z M16,3 C8.832,3,3,8.832,3,16s5.832,13,13,13s13-5.832,13-13S23.168,3,16,3z" })
      ])
    ], -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["RecipeBuilder_SearchBox"], {
        isSearchLoading: $setup.isSearchLoading,
        modelValue: $setup.searchTerm,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchTerm) = $event)),
        onKeyup: [
          _withKeys($setup.resetSearch, ["esc"]),
          _withKeys($setup.handleUp, ["up"]),
          _withKeys($setup.handleDown, ["down"]),
          _withKeys(_withModifiers($setup.selectResult, ["prevent"]), ["enter"])
        ]
      }, null, 8 /* PROPS */, ["isSearchLoading", "modelValue", "onKeyup"]),
      _createVNode($setup["QuickAdd_ResultList"], {
        results: $setup.results,
        selectedResultIndex: $setup.selectedResultIndex,
        onSelect: $setup.handleSelectResult
      }, null, 8 /* PROPS */, ["results", "selectedResultIndex"])
    ])
  ]))
}