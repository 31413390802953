import type { IPublicInventoryItem } from "types/InventoryItem"
import type { IShopCartItem } from "types/ShopCartItem"

export { convertPublicInventoryItemToShopCartItem }

const convertPublicInventoryItemToShopCartItem = (
	item: IPublicInventoryItem,
): IShopCartItem => {
	return {
		...item,
		quantity: 0,
		netPrice: 0,
		discount: 0,
	}
}
