import { defineComponent as _defineComponent } from 'vue'
import { useHead } from "@unhead/vue"


export default /*@__PURE__*/_defineComponent({
  __name: '404.page',
  setup(__props, { expose: __expose }) {
  __expose();

useHead({
	title: "404 Page Not Found | Gnome Brew - Homebrew and Draft Supplies",
	link: [{ rel: "canonical", href: "https://gnomebrewshop.com/404" }],
	meta: [{ name: "robots", content: "noindex" }],
})

const __returned__ = { get useHead() { return useHead } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})