import { defineComponent as _defineComponent } from 'vue'
import { useOrderStore } from "@website/stores/order.js"
import { useRecipeBuilderStore } from "@website/stores/recipeBuilder"
import { storeToRefs } from "pinia"
import { computed } from "vue"
import RecipeBuilder_QuickAdd from "./QuickAdd.vue"
import CartItem from "./RecipeCart_Item.vue"

import type { IRecipe } from "types/Recipe"


export default /*@__PURE__*/_defineComponent({
  __name: 'recipeCart',
  setup(__props, { expose: __expose }) {
  __expose();

const orderStore = useOrderStore()
const recipeBuilderStore = useRecipeBuilderStore()
const { needsMilling, workingRecipe: recipe } = storeToRefs(recipeBuilderStore)
const subtotal = computed((): number => {
	let subTotal = 0
	recipe.value.items.forEach((item) => {
		subTotal += item.price * item.quantity
	})
	return subTotal
})
const sortedCart = computed((): IRecipe["items"] => {
	let cartCopy = recipe.value.items
	cartCopy.sort((a, b) => {
		let aCategories = [a.canonical_category]
		if (a.categories) {
			aCategories = aCategories.concat(a.categories)
		}
		const aCategory = aCategories.find((category) => {
			return orderStore.sortCategories.includes(category)
		})
		let bCategories = [b.canonical_category]
		if (b.categories) bCategories = bCategories.concat(b.categories)
		const bCategory = bCategories.find((category) => {
			return orderStore.sortCategories.includes(category)
		})
		const aIndex = aCategory
			? orderStore.sortCategories.indexOf(aCategory)
			: Number.MAX_SAFE_INTEGER
		const bIndex = bCategory
			? orderStore.sortCategories.indexOf(bCategory)
			: Number.MAX_SAFE_INTEGER
		return aIndex - bIndex
	})
	return cartCopy
})

const clearRecipe = (): void => {
	sortedCart.value.forEach((item) => {
		//item.isSaved = false

		//changed 1.2.24
		//old line: item.quantity = undefined
		item.quantity = 0
	})
	recipeBuilderStore.clearWorkingRecipe()
}

const __returned__ = { orderStore, recipeBuilderStore, needsMilling, recipe, subtotal, sortedCart, clearRecipe, get useOrderStore() { return useOrderStore }, get useRecipeBuilderStore() { return useRecipeBuilderStore }, get storeToRefs() { return storeToRefs }, computed, RecipeBuilder_QuickAdd, CartItem }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})