import { createElementVNode as _createElementVNode, openBlock as _openBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "noprint",
  id: "AppHeader"
}
const _hoisted_2 = { id: "header-toolbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "header-toolbar-item",
        id: "topmenu-hamburger-label",
        onClick: $setup.toggleMenu
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("svg", {
          viewBox: "0 0 100 80",
          width: "40",
          height: "40",
          fill: "#fff"
        }, [
          _createElementVNode("rect", {
            width: "100",
            height: "15",
            rx: "5px"
          }),
          _createElementVNode("rect", {
            y: "30",
            width: "100",
            height: "15",
            rx: "5px"
          }),
          _createElementVNode("rect", {
            y: "60",
            width: "100",
            height: "15",
            rx: "5px"
          })
        ], -1 /* HOISTED */)
      ])),
      _createVNode(_component_router_link, {
        class: "header-toolbar-item headertoolbar-buttonbox",
        to: {name: 'Recipe Builder', params: {recipeID: 0}},
        onClick: $setup.closeMenu
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("div", { class: "headertoolbar-buttonbox-text" }, "Build Your Recipe", -1 /* HOISTED */),
          _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            height: "48",
            width: "48"
          }, [
            _createElementVNode("path", {
              fill: "#fff",
              d: "M11.9 43q-1.2 0-2.05-.85Q9 41.3 9 40.1V7.9q0-1.2.85-2.05Q10.7 5 11.9 5h16.6L39 15.5v7.45h-2.25V17H27V7.25H11.9q-.25 0-.45.2t-.2.45v32.2q0 .25.2.45t.45.2h12.85V43Zm-.65-2.25V7.25 40.75Zm25.3-11.05 1.15 1.1-8.05 8.05v2.45h2.45l8.05-8.05 1.15 1.15-8.55 8.55H28v-4.7Zm4.75 4.7-4.75-4.7 2.7-2.75q.45-.45 1.025-.45t1.025.45l2.75 2.75q.4.4.4 1t-.4 1Z"
            })
          ], -1 /* HOISTED */)
        ])),
        _: 1 /* STABLE */
      }),
      (!$setup.isLoggedIn)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            class: "header-toolbar-item mobile-only",
            to: "/",
            onClick: $setup.closeMenu
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("img", { src: "https://gnomebrew-cdn.s3.us-east-2.amazonaws.com/images/logos/gnome-brew-logo-text-only-white-100.png" }, null, -1 /* HOISTED */)
            ])),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _createVNode($setup["Search"], {
        class: "header-toolbar-item",
        onCloseHeaderMenu: $setup.closeMenu
      }),
      _createVNode($setup["AppHeader_Account"], {
        class: "header-toolbar-item",
        closeMenu: $setup.closeMenu
      }),
      _createVNode($setup["HeaderCart"], {
        class: "header-toolbar-item",
        onCloseHeaderMenu: $setup.closeMenu
      })
    ]),
    _withDirectives(_createElementVNode("input", {
      id: "topmenu-hamburger",
      type: "checkbox",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.isHamburgerMenuOpen) = $event))
    }, null, 512 /* NEED_PATCH */), [
      [_vModelCheckbox, $setup.isHamburgerMenuOpen]
    ]),
    _createElementVNode("div", {
      id: "topmenu",
      onClick: $setup.closeMenu
    }, [
      _createVNode(_component_router_link, {
        class: "no-mobile topmenu-item",
        to: "/"
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("img", {
            height: "98",
            width: "98",
            src: "https://gnomebrew-cdn.s3.us-east-2.amazonaws.com/images/logos/gnome-brew-logo-with-text-full-color-100.png"
          }, null, -1 /* HOISTED */)
        ])),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_router_link, {
        class: "mobile-only topmenu-item",
        to: "/"
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("Home")
        ])),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_router_link, {
        class: "topmenu-item",
        to: "/shop"
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode("Shop")
        ])),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_router_link, {
        class: "topmenu-item",
        to: "/simply-brewing"
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [
          _createTextVNode("Simply Brewing® Kits")
        ])),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_router_link, {
        class: "topmenu-item",
        to: "/classes-and-events"
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createTextVNode("Classes & Events")
        ])),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_router_link, {
        class: "topmenu-item",
        to: "/certificates"
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [
          _createTextVNode("Gift Certificates")
        ])),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_router_link, {
        class: "topmenu-item",
        to: "/newsletter"
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [
          _createTextVNode("News")
        ])),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_router_link, {
        class: "topmenu-item",
        to: "/visit-us"
      }, {
        default: _withCtx(() => _cache[11] || (_cache[11] = [
          _createTextVNode("Visit Us")
        ])),
        _: 1 /* STABLE */
      })
    ])
  ]))
}