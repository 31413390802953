import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "RecipeBuilderHeader" }
const _hoisted_2 = { id: "header-toolbar" }
const _hoisted_3 = { id: "recipeNameWrapper" }
const _hoisted_4 = { id: "recipeName" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button_Icon_Edit = _resolveComponent("Button_Icon_Edit")!
  const _component_Button_Medium = _resolveComponent("Button_Medium")!
  const _component_ButtonBar = _resolveComponent("ButtonBar")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { id: "recipeBuilderTitle" }, "Recipe Builder", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString($setup.workingRecipe.name), 1 /* TEXT */),
        _createVNode(_component_Button_Icon_Edit, { onClick: $setup.editRecipeName })
      ]),
      _createVNode($setup["RecipeCart_Container"]),
      _createVNode(_component_ButtonBar, { class: "horizontal" }, {
        default: _withCtx(() => [
          _createVNode(_component_Button_Medium, { onClick: $setup.handleSaveRecipe }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Save")
            ])),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_Button_Medium, { onClick: $setup.handleExit }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Exit")
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}