import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "resultList" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.results, (searchItem, index) => {
      return (_openBlock(), _createBlock($setup["SearchItem"], {
        key: searchItem.item.sku,
        searchItem: searchItem,
        isSelected: $setup.isSelected(index),
        onClick: ($event: any) => (_ctx.$emit('select', searchItem.item.sku, index))
      }, null, 8 /* PROPS */, ["searchItem", "isSelected", "onClick"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}