import { defineComponent as _defineComponent } from 'vue'
import { logger as $log } from "@Plugins/clientLogger"
import { useModal } from "@Plugins/gbModal/gbModal"
import { useRecipeBuilderStore } from "@website/stores/recipeBuilder"
import { storeToRefs } from "pinia"
import { event as GTagEvent } from "vue-gtag"
import { useRouter } from "vue-router"
import RecipeCart_Container from "./RecipeCart_Container.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeBuilder_Header',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter()

const recipeBuilderStore = useRecipeBuilderStore()
const { workingRecipe, itemCount, hasUnsavedChanges } =
	storeToRefs(recipeBuilderStore)
const editRecipeName = (): void => {
	useModal.show("newRecipeDialog", {
		recipeName: workingRecipe.value.name,
	})
}

const handleSaveRecipe = (): void => {
	if (itemCount.value === 0) {
		alert("You must have at least one item in your recipe.")
		return
	}
	recipeBuilderStore.saveWorkingRecipe()
	GTagEvent("save_recipe", {
		event_category: "recipe_builder",
	})
	void router.push("/shop")
}

const handleExit = (): void => {
	if (hasUnsavedChanges.value) {
		const exitWitoutSaving = window.confirm(
			"Are You Sure You'd Like To Exit Without Saving?",
		)
		if (exitWitoutSaving === false) return
		else {
			$log.info("Exiting Recipe Builder Without Saving Recipe", {
				recipeID: workingRecipe.value.id,
				name: workingRecipe.value.name,
			})
		}
	} else {
		$log.info("Exiting Recipe Builder With No Unsaved Changes", {
			recipeID: workingRecipe.value.id,
			name: workingRecipe.value.name,
		})
	}
	recipeBuilderStore.resetWorkingRecipe()
	void router.push("/shop")
}

const __returned__ = { router, recipeBuilderStore, workingRecipe, itemCount, hasUnsavedChanges, editRecipeName, handleSaveRecipe, handleExit, get $log() { return $log }, get useModal() { return useModal }, get useRecipeBuilderStore() { return useRecipeBuilderStore }, get storeToRefs() { return storeToRefs }, get GTagEvent() { return GTagEvent }, get useRouter() { return useRouter }, RecipeCart_Container }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})