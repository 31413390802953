import { createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "newRecipeDialog" }
const _hoisted_2 = { class: "form-aligned" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button_Large = _resolveComponent("Button_Large")!
  const _component_ButtonBar_Center = _resolveComponent("ButtonBar_Center")!
  const _component_Drawer = _resolveComponent("Drawer")!
  const _component_gbModal = _resolveComponent("gbModal")!

  return (_openBlock(), _createBlock(_component_gbModal, {
    name: "newRecipeDialog",
    height: "auto",
    adaptive: "",
    onOpened: $setup.opened
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Drawer, {
          alwaysOpen: true,
          header: "Name Your Recipe"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _cache[1] || (_cache[1] = _createElementVNode("label", null, "Recipe Name", -1 /* HOISTED */)),
              _withDirectives(_createElementVNode("input", {
                ref: "nameInput",
                type: "text",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.workingRecipeName) = $event)),
                onKeyup: _withKeys($setup.updateRecipeName, ["enter"])
              }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
                [_vModelText, $setup.workingRecipeName]
              ])
            ]),
            _createVNode(_component_ButtonBar_Center, null, {
              default: _withCtx(() => [
                ($props.isNewRecipe)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_Button_Large, { onClick: $setup.updateRecipeName }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode("Build")
                        ])),
                        _: 1 /* STABLE */
                      }),
                      ($props.isNewRecipe)
                        ? (_openBlock(), _createBlock(_component_Button_Large, {
                            key: 0,
                            onClick: $setup.cancelNewRecipe
                          }, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode("Cancel")
                            ])),
                            _: 1 /* STABLE */
                          }))
                        : _createCommentVNode("v-if", true)
                    ], 64 /* STABLE_FRAGMENT */))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createVNode(_component_Button_Large, { onClick: $setup.updateRecipeName }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode("Save Name")
                        ])),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_Button_Large, { onClick: $setup.cancelRenameRecipe }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode("Cancel")
                        ])),
                        _: 1 /* STABLE */
                      })
                    ], 64 /* STABLE_FRAGMENT */)),
                _createVNode(_component_Button_Large, { onClick: $setup.gotoAboutRecipeBuilder }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("About")
                  ])),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }))
}