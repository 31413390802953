import { getObjectByType, getObjectsByType } from "@shared/api/JRPHelper"
import type { FuseIndex } from "fuse.js"
import api from "./api"

import { useQuery } from "@tanstack/vue-query"
import type { IPublicInventoryItem } from "types/InventoryItem"
import { computed } from "vue"

const inventoryPath = "inventory-items/"
const queryKey_Inventory = "inventory"
export {
	getItemBySKU,
	getRecipeBuilderItems,
	getRecipeBuilderSearchIndex,
	getShopItemsByCategoryID,
	useGetInventory,
}

const getInventory = async (): Promise<IPublicInventoryItem[]> => {
	try {
		const { data } = await api.get(inventoryPath, {
			validateStatus: (status) => {
				return status === 200
			},
		})
		return getObjectsByType<IPublicInventoryItem>(
			data,
			"PublicInventoryItem",
		)
	} catch (error) {
		return Promise.reject(error)
	}
}
const useGetInventory = () => {
	const { data, status } = useQuery<IPublicInventoryItem[]>({
		queryKey: [queryKey_Inventory],
		queryFn: getInventory,
	})
	const inventory = computed(
		() => data.value ?? ([] as IPublicInventoryItem[]),
	)
	return { inventory, status }
}

const getShopItemsByCategoryID = async (
	categoryID: number,
): Promise<IPublicInventoryItem[]> => {
	try {
		const { data } = await api.get(
			inventoryPath + "categoryID/" + categoryID,
			{
				validateStatus: (status) => {
					return status === 200
				},
			},
		)
		return getObjectsByType<IPublicInventoryItem>(
			data,
			"PublicInventoryItem",
		)
	} catch (error) {
		return Promise.reject(error)
	}
}

const getItemBySKU = async (sku: number): Promise<IPublicInventoryItem> => {
	try {
		const { data } = await api.get(inventoryPath + "sku/" + sku, {
			validateStatus: (status) => {
				return status === 200
			},
		})
		const item = getObjectByType<IPublicInventoryItem>(
			data,
			"PublicInventoryItem",
		)
		if (!item) {
			return Promise.reject(new Error("Item not found"))
		}
		return item
	} catch (error) {
		return Promise.reject(error)
	}
}
const getRecipeBuilderItems = async (): Promise<IPublicInventoryItem[]> => {
	try {
		const { data } = await api.get(inventoryPath + "recipe-builder", {
			validateStatus: (status) => {
				return status === 200
			},
		})
		return getObjectsByType<IPublicInventoryItem>(
			data,
			"PublicInventoryItem",
		)
	} catch (error) {
		return Promise.reject(error)
	}
}

const getRecipeBuilderSearchIndex = async (): Promise<
	FuseIndex<IPublicInventoryItem>
> => {
	try {
		const { data: recipeBuilderSearchIndex } = await api.get<
			FuseIndex<IPublicInventoryItem>
		>(inventoryPath + "recipe-builder-search-index", {
			validateStatus: (status) => {
				return status === 200
			},
		})
		return recipeBuilderSearchIndex
	} catch (error) {
		return Promise.reject(error)
	}
}
