import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "resultItem-cell" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "resultItem-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["resultItem", { selected: $props.isSelected }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", { src: $setup.image }, null, 8 /* PROPS */, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, _toDisplayString($props.searchItem.item.name), 1 /* TEXT */)
    ])
  ], 2 /* CLASS */))
}