import { defineComponent as _defineComponent } from 'vue'
import { ref } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'gbModalProcessing',
  setup(__props, { expose: __expose }) {
  __expose();

const headerMessage = ref("Processing...")
const additionalMessage = ref(false)

const beforeOpen = (params): void => {
	if (params) {
		if (params.headerMessage) headerMessage.value = params.headerMessage
		if (params.additionalMessage)
			additionalMessage.value = params.additionalMessage
	}
}

const __returned__ = { headerMessage, additionalMessage, beforeOpen, ref }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})