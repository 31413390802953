import { defineComponent as _defineComponent } from 'vue'
import { useModal } from "@Plugins/gbModal/gbModal"
import { ref } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'gbDialog',
  setup(__props, { expose: __expose }) {
  __expose();

const title = ref("")
const message = ref(false)
const buttonText = ref("OK")

const beforeOpen = (params): void => {
	if (params) {
		if (params.title) title.value = params.title
		if (params.message) message.value = params.message
		if (params.buttonText) buttonText.value = params.buttonText
	}
}
const close = (): void => {
	useModal.hide("gbDialog")
}

const __returned__ = { title, message, buttonText, beforeOpen, close, get useModal() { return useModal }, ref }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})