import { defineComponent as _defineComponent } from 'vue'
import { logger as $log } from "@Plugins/clientLogger"
import { useOrderStore } from "@website/stores/order.js"
import { computed } from "vue"

import type { IRecipe } from "types/Recipe"


export default /*@__PURE__*/_defineComponent({
  __name: 'recipe',
  props: {
    recipe: {},
    editmode: { type: Boolean, default: false }
  },
  emits: ["editRecipe"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

//imports
const props = __props

const emit = __emit
const orderStore = useOrderStore()

const subtotal = computed((): number => {
	let subTotal = 0
	props.recipe.items.forEach((item) => {
		subTotal += item.price * item.quantity
	})
	return Math.round(subTotal * 100) / 100
})

const editRecipe = (): void => {
	emit("editRecipe", props.recipe)
}
const removeRecipe = (): void => {
	let confirmed = window.confirm(
		"Are you sure you would like to delete this receipe?",
	)
	if (confirmed === true) orderStore.removeRecipe(props.recipe.id)
	$log.info("Recipe Removed From Cart", {
		name: props.recipe.name,
	})
}

const __returned__ = { props, emit, orderStore, subtotal, editRecipe, removeRecipe, get $log() { return $log }, get useOrderStore() { return useOrderStore }, computed }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})