import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, vModelText as _vModelText, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { id: "recipe" }
const _hoisted_2 = {
  key: 0,
  class: "center"
}
const _hoisted_3 = {
  key: 1,
  class: "cart"
}
const _hoisted_4 = { class: "cart-item footer" }
const _hoisted_5 = { class: "cart-item-cell pricecol" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["RecipeBuilder_QuickAdd"]),
    ($setup.recipe.items.length == 0)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_2, "Add Items To Your Recipe"))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _cache[8] || (_cache[8] = _createStaticVNode("<div class=\"cart-item header\" data-v-9d6cdb70><div class=\"cart-item-cell imagecol\" data-v-9d6cdb70></div><div class=\"cart-item-cell namecol\" data-v-9d6cdb70>Item</div><div class=\"cart-item-cell pricecol\" data-v-9d6cdb70>Price</div><div class=\"cart-item-cell inputcol\" data-v-9d6cdb70>Quantity</div><div class=\"cart-item-cell pricecol\" data-v-9d6cdb70></div><div class=\"cart-item-cell buttoncol\" data-v-9d6cdb70></div></div>", 1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortedCart, (item) => {
            return (_openBlock(), _createBlock($setup["CartItem"], {
              key: item.sku,
              item: item,
              recipeID: $setup.recipe.id,
              editmode: true
            }, null, 8 /* PROPS */, ["item", "recipeID"]))
          }), 128 /* KEYED_FRAGMENT */)),
          _createElementVNode("span", null, [
            _createElementVNode("div", _hoisted_4, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "cart-item-cell imagecol" }, null, -1 /* HOISTED */)),
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "cart-item-cell namecol" }, null, -1 /* HOISTED */)),
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "cart-item-cell pricecol" }, null, -1 /* HOISTED */)),
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "cart-item-cell inputcol" }, "Subtotal:", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_5, "$" + _toDisplayString($setup.subtotal.toFixed(2)), 1 /* TEXT */),
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "cart-item-cell buttoncol" }, null, -1 /* HOISTED */))
            ])
          ])
        ])),
    _createElementVNode("form", {
      class: "form-aligned",
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
    }, [
      ($setup.needsMilling)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _cache[9] || (_cache[9] = _createElementVNode("label", null, "Mill Grains", -1 /* HOISTED */)),
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.recipe.millGrains) = $event))
            }, null, 512 /* NEED_PATCH */), [
              [_vModelCheckbox, $setup.recipe.millGrains]
            ])
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true),
      _cache[10] || (_cache[10] = _createElementVNode("label", null, "Special Instructions", -1 /* HOISTED */)),
      _withDirectives(_createElementVNode("textarea", {
        class: "noresize",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.recipe.specialInstructions) = $event))
      }, "<!--ButtonBarButton_Large(\n\tv-if=\"(recipe.items.length > 0) && newRecipe\"\n\t@click=\"save\"\n) Save\nButton_Large(\n\tv-if=\"newRecipe\"\n\t@click=\"cancel\"\n) Cancel\nButton_Large(\n\tv-else\n\t@click=\"close\"\n) Save-->", 512 /* NEED_PATCH */), [
        [_vModelText, $setup.recipe.specialInstructions]
      ])
    ], 32 /* NEED_HYDRATION */)
  ]))
}