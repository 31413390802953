import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { logger as $log } from "@Plugins/clientLogger"
import { useQuery } from "@tanstack/vue-query"
import { getRecipeBuilderShopCategories } from "@website/api/shopCategories"
import { useRecipeBuilderStore } from "@website/stores/recipeBuilder"
import { storeToRefs } from "pinia"
import { watch } from "vue"

import type { IShopCategory } from "types/ShopCategory"


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeBuilder_Categories',
  props: {
    "modelValue": {},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose }) {
  __expose();

const recipeBuilderStore = useRecipeBuilderStore()
const filterCategory = _useModel<IShopCategory>(__props, "modelValue")
const { workingRecipe: recipe } = storeToRefs(recipeBuilderStore)

const { data: recipeBuilderCategories } = useQuery<IShopCategory[]>({
	queryKey: ["recipeBuilderCategories"],
	queryFn: getRecipeBuilderShopCategories,
})

watch(
	recipeBuilderCategories,
	(newVal) => {
		if (newVal && newVal.length > 0 && !filterCategory.value) {
			filterCategory.value = newVal[0]
		}
	},
	{ immediate: true },
)

const setFilterCategory = (category: IShopCategory): void => {
	filterCategory.value = category
	$log.info("Category Selected", {
		recipeID: recipe.value.id,
		recipe: recipe.value.name,
		categoryID: category.id,
		name: category.name,
	})
}

const __returned__ = { recipeBuilderStore, filterCategory, recipe, recipeBuilderCategories, setFilterCategory, get $log() { return $log }, get useQuery() { return useQuery }, get getRecipeBuilderShopCategories() { return getRecipeBuilderShopCategories }, get useRecipeBuilderStore() { return useRecipeBuilderStore }, get storeToRefs() { return storeToRefs }, watch }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})