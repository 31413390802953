import { defineComponent as _defineComponent } from 'vue'
import { logger as $log } from "@Plugins/clientLogger"
import { useHead } from "@unhead/vue"
import { getStaticPageContent } from "@website/api/app"
import { nextTick, ref } from "vue"
import { useRoute, useRouter } from "vue-router"


export default /*@__PURE__*/_defineComponent({
  __name: 'hbt.page',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute()
const router = useRouter()
const html = ref("")

useHead({
	title: "Gnome Brew - Your Home For Homebrew",
	link: [{ rel: "canonical", href: "https://gnomebrewshop.com" }],
})

const loadPageContent = async (): Promise<void> => {
	const displayPage = "hbt"

	try {
		const { status, html: fetchedHTML } =
			await getStaticPageContent(displayPage)
		if (status === 404) {
			$log.info(
				"app/components/content 404",
				{ path: route.fullPath },
				{ query: route.query },
				{ params: route.params },
			)
			await router.replace("/404")
		} else {
			html.value = fetchedHTML
			await nextTick()
			const contentContainer =
				document.getElementById("content-container")
			contentContainer?.scrollTo({ top: 0, behavior: "smooth" })
		}
	} catch (error) {
		if (
			["server_error", "response_error", "unknown_error"].includes(
				error.message,
			)
		) {
			alert(
				"Server Error--Please Try Again Later.  If You Receive This Message Again, Please Contact Us at gnomes@gnomebrewshop.com or 773-961-7750.  Sorry For The Inconvenience.",
			)
		} else if (error.message == "request_error") {
			alert(
				"There Appears To Be A Problem With The Network.  Please Try Again.",
			)
		}
	}
}

loadPageContent()

const __returned__ = { route, router, html, loadPageContent, get $log() { return $log }, get useHead() { return useHead }, get getStaticPageContent() { return getStaticPageContent }, nextTick, ref, get useRoute() { return useRoute }, get useRouter() { return useRouter } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})