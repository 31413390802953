import { defineComponent as _defineComponent } from 'vue'
import { useAccountStore } from "@website/stores/account"
import { storeToRefs } from "pinia"


export default /*@__PURE__*/_defineComponent({
  __name: 'appHeader-account',
  setup(__props, { expose: __expose }) {
  __expose();

const accountStore = useAccountStore()
const { isLoggedIn, profile } = storeToRefs(accountStore)

const __returned__ = { accountStore, isLoggedIn, profile, get useAccountStore() { return useAccountStore }, get storeToRefs() { return storeToRefs } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})