import { useGetLocalDeliverySettings } from "@shared/api/api"
import { useGetInventory } from "@website/api/inventory"
import cloneDeep from "lodash-es/cloneDeep"
import { defineStore } from "pinia"
import { computed, ref } from "vue"
import OrderStatus from "../../../../gb_modules/OrderStatus"
import {
	doesDiscountApply as checkDiscount,
	constructDiscountPipe,
} from "./fnDiscounts.js"

import { convertPublicInventoryItemToShopCartItem } from "@shared/functions/fnInventoryItem"
import { logger as log } from "@shared/plugins/clientLogger"
import type { ICashCertificate } from "types/CashCertificate.js"
import type {
	ICustomerProfile,
	IDeliveryAddress,
	IShippingAddress,
} from "types/Customer"
import type { IDiscount } from "types/Discount"
import type { IPublicInventoryItem } from "types/InventoryItem"
import type { IOrderData, TDeliveryMethod } from "types/Order"
import type { ICashCertificatePayment } from "types/Payment"
import type { IReceiptData, IReceiptTaxRates } from "types/Receipt"
import type { IRecipe } from "types/Recipe"
import type { IShopCartItem } from "types/ShopCartItem"
import type { Ref } from "vue"

const { fee: localDeliveryFee, zipcodes: localDeliveryzipcodes } =
	useGetLocalDeliverySettings()

export const useOrderStore = defineStore("orders", () => {
	const { inventory, status } = useGetInventory()
	const getInventoryItemBySKU = (sku: number): IPublicInventoryItem => {
		if (inventory.value === undefined) {
			log.error(status, "Inventory Not Loaded")
			throw new Error("Inventory Not Loaded")
		}
		const item = inventory.value.find((item) => item.sku === sku)
		if (item === undefined) {
			log.error(item, "Inventory Item Not Found")
			throw new Error("Inventory Item Not Found")
		}
		return item
	}

	const cart: Ref<IShopCartItem[]> = ref([])
	const recipes: Ref<IRecipe[]> = ref([])

	const taxRates: IReceiptTaxRates = {
		grocery: 0.0225,
		general: 0.1025,
	}
	const freeShippingMinimum = 69
	const flatRateShipping = 8.99
	const millFee = 1.5

	const millGrains = ref(false)
	const deliveryMethod = ref<TDeliveryMethod>()
	const specialInstructions = ref("")
	const customerProfile = ref<ICustomerProfile>({
		firstname: "",
		lastname: "",
		email: "",
		phone: "",
	})
	const cashCertificates = ref<ICashCertificate[]>([])
	const discounts = ref<IDiscount[]>([])
	const deliveryAddress = ref<IDeliveryAddress>({
		token: "",
		firstname: "",
		lastname: "",
		address1: "",
		address2: "",
		city: "",
		state: "",
		zipcode: "",
		phone: "",
		deliveryInstructions: "",
	})
	const shippingAddress = ref<IShippingAddress>({
		token: "",
		firstname: "",
		lastname: "",
		address1: "",
		address2: "",
		city: "",
		state: "",
		zipcode: "",
	})

	const filterCategory = ref("Clearance")
	const sortCategories = [
		37, 77, 80, 81, 36, 82, 17, 18, 15, 21, 23, 24, 26, 38, 44, 41, 84,
	]

	const getItemInCartBySKU = (sku: number): IShopCartItem | undefined => {
		return cart.value.find((i) => i.sku === sku)
	}

	const cartNeedsMilling = computed((): boolean => {
		const needsMilling = cart.value.some(
			(item) => item.needs_milling === true,
		)
		return needsMilling
	})

	const itemsThatNeedMilling = computed((): IShopCartItem[] => {
		const needsMilling = cart.value.filter(
			(item) => item.needs_milling === true,
		)
		return needsMilling
	})

	const millCount = computed((): number => {
		let millCount = 0

		if (millGrains.value) millCount += itemsThatNeedMilling.value.length
		recipes.value.forEach((recipe) => {
			if (recipe.millGrains) millCount++
		})
		return millCount
	})

	const totalMillingFee = computed((): number => {
		if (deliveryMethod.value === "shipment") {
			return millCount.value * millFee
		} else {
			return 0
		}
	})
	const doesDiscountApply = (discount: IDiscount): boolean => {
		const discountedCart = [] as IShopCartItem[]
		cart.value.forEach((item) => {
			const inventoryItem = getInventoryItemBySKU(item.sku)
			if (inventoryItem) {
				const shopCartItem =
					convertPublicInventoryItemToShopCartItem(inventoryItem)
				shopCartItem.quantity = item.quantity
				discountedCart.push(shopCartItem)
			}
		})
		recipes.value.forEach((recipe) => {
			recipe.items.forEach((item) => {
				const inventoryItem = getInventoryItemBySKU(item.sku)
				if (inventoryItem) {
					const shopCartItem =
						convertPublicInventoryItemToShopCartItem(inventoryItem)
					shopCartItem.quantity = item.quantity
					discountedCart.push(shopCartItem)
				}
			})
		})
		return checkDiscount(discountedCart, discount)
	}

	const itemsThatWontShip = computed((): IShopCartItem[] => {
		const itemsThatWontShip = cart.value.filter(
			(item) => item.ships === false,
		)
		return itemsThatWontShip
	})

	const canShip = computed((): boolean => {
		if (itemsThatWontShip.value.length > 0) return false
		else return true
	})

	const itemsThatShipFree = computed((): IShopCartItem[] => {
		const itemsThatShipFree = cart.value.filter(
			(item) => item.free_shipping_eligible === true,
		)
		return itemsThatShipFree
	})

	const itemsThatDontShipFree = computed((): IShopCartItem[] => {
		const itemsThatDontShipFree = cart.value.filter(
			(item) => item.free_shipping_eligible === false,
		)
		return itemsThatDontShipFree
	})

	const subtotalOfItemsThatShipFree = computed((): number => {
		const items = itemsThatShipFree.value
		let subtotal = 0
		items.forEach((item) => {
			subtotal += item.netPrice * 100 * item.quantity
		})
		recipes.value.forEach((recipe) => {
			recipe.items.forEach((item) => {
				subtotal += item.price * 100 * item.quantity
			})
		})
		return Math.round(subtotal) / 100
	})

	const freeShippingEligible = computed((): boolean => {
		if (subtotalOfItemsThatShipFree.value > freeShippingMinimum) {
			if (itemsThatDontShipFree.value.length === 0) return true
			else return false
		} else return false
	})

	const flatRateShippingEligible = computed((): boolean => {
		if (subtotalOfItemsThatShipFree.value > freeShippingMinimum) {
			if (itemsThatDontShipFree.value.length === 0) return true
			else return false
		} else return false
	})

	const shippingCost = computed((): number => {
		if (canShip.value === false) return 0
		else if (freeShippingEligible.value) return 0
		else if (flatRateShippingEligible.value) return flatRateShipping
		else if (subtotalOfItemsThatShipFree.value > freeShippingMinimum) {
			let totalShippingFee = 0
			itemsThatDontShipFree.value.forEach((item) => {
				totalShippingFee += item.shipping_fee * item.quantity
			})
			return totalShippingFee
		} else {
			let totalShippingFee = flatRateShipping
			itemsThatDontShipFree.value.forEach((item) => {
				totalShippingFee += item.shipping_fee
			})
			return totalShippingFee
		}
	})

	const finalShippingCostForSelectedDeliveryMethod = computed((): number => {
		if (deliveryMethod.value === "pickup") return 0
		else if (deliveryMethod.value === "delivery")
			return localDeliveryFee.value
		else if (deliveryMethod.value === "shipment") return shippingCost.value
		else return 0
	})

	const getRecipeByID = (id: number): IRecipe | null => {
		const recipe = recipes.value.find((i) => i.id === id)
		if (recipe) return recipe
		else return null
	}

	const getNextRecipeID = (): number => {
		let nextID = 1
		if (recipes.value.length > 0) {
			const lastID = recipes.value[recipes.value.length - 1].id
			nextID = lastID + 1
		}
		return nextID
	}

	const getItemInRecipe = (
		recipeID: number,
		sku: number,
	): IShopCartItem | undefined => {
		const recipe = recipes.value.find((i) => i.id === recipeID)
		if (recipe) return recipe.items.find((i) => i.sku === sku)
		else return undefined
	}

	const getQuantityOrderedElsewhere = (
		sku: number,
		recipeID?: number,
	): number => {
		let quantityOrdered = 0
		if (recipeID) {
			const cartItem = getItemInCartBySKU(sku)
			if (cartItem) quantityOrdered += cartItem.quantity
		}
		recipes.value.forEach((recipe) => {
			if (recipe.id === recipeID) return
			const recipeItem = getItemInRecipe(recipe.id, sku)
			if (recipeItem) quantityOrdered += recipeItem.quantity
		})
		return quantityOrdered
	}

	const cashCertificateBalance = computed((): number => {
		let balance = 0
		cashCertificates.value.forEach(
			(certificate) => (balance += certificate.currentValue),
		)
		return balance
	})

	const grossSalesGeneral = computed((): number => {
		let generalSales = 0
		cart.value.forEach((item) => {
			if (!item.grocery && !item.notax) {
				generalSales += item.price * 100 * item.quantity
			}
		})
		recipes.value.forEach((recipe) => {
			recipe.items.forEach((item) => {
				if (!item.grocery && !item.notax) {
					generalSales += item.price * 100 * item.quantity
				}
			})
		})
		return Math.round(generalSales) / 100
	})

	const grossSalesGrocery = computed((): number => {
		let grocerySales = 0
		cart.value.forEach((item) => {
			if (item.grocery) {
				grocerySales += item.price * 100 * item.quantity
			}
		})
		recipes.value.forEach((recipe) => {
			recipe.items.forEach((item) => {
				if (item.grocery) {
					grocerySales += item.price * 100 * item.quantity
				}
			})
		})
		return Math.round(grocerySales) / 100
	})

	const grossSalesNoTax = computed((): number => {
		let noTaxSales = 0
		cart.value.forEach((item) => {
			if (item.notax) {
				noTaxSales += item.price * 100 * item.quantity
			}
		})
		recipes.value.forEach((recipe) => {
			recipe.items.forEach((item) => {
				if (item.notax) {
					noTaxSales += item.price * 100 * item.quantity
				}
			})
		})
		return Math.round(noTaxSales) / 100
	})

	const grossSubtotal = computed((): number => {
		return (
			grossSalesGeneral.value +
			grossSalesGrocery.value +
			grossSalesNoTax.value
		)
	})

	const totalDiscount = computed((): number => {
		let discount = 0

		cart.value.forEach((item) => {
			discount += item.discount * 100 * item.quantity
		})
		recipes.value.forEach((recipe) => {
			recipe.items.forEach((item) => {
				discount += item.discount * 100 * item.quantity
			})
		})
		return Math.round(discount) / 100
	})

	const netSalesGeneral = computed((): number => {
		let generalSales = 0

		cart.value.forEach((item) => {
			if (!item.grocery && !item.notax) {
				generalSales += item.netPrice * 100 * item.quantity
			}
		})
		recipes.value.forEach((recipe) => {
			recipe.items.forEach((item) => {
				if (!item.grocery && !item.notax) {
					generalSales += item.netPrice * 100 * item.quantity
				}
			})
		})
		return Math.round(generalSales) / 100
	})

	const netSalesGrocery = computed((): number => {
		let grocerySales = 0

		cart.value.forEach((item) => {
			if (item.grocery) {
				grocerySales += item.netPrice * 100 * item.quantity
			}
		})
		recipes.value.forEach((recipe) => {
			recipe.items.forEach((item) => {
				if (item.grocery) {
					grocerySales += item.netPrice * 100 * item.quantity
				}
			})
		})
		return Math.round(grocerySales) / 100
	})

	const netSalesNoTax = computed((): number => {
		let noTaxSales = 0

		cart.value.forEach((item) => {
			if (item.notax) {
				noTaxSales += item.netPrice * 100 * item.quantity
			}
		})
		recipes.value.forEach((recipe) => {
			recipe.items.forEach((item) => {
				if (item.notax) {
					noTaxSales += item.netPrice * 100 * item.quantity
				}
			})
		})
		return Math.round(noTaxSales) / 100
	})

	const netSubtotal = computed((): number => {
		return (
			Math.round(
				netSalesGeneral.value * 100 +
					netSalesGrocery.value * 100 +
					netSalesNoTax.value * 100,
			) / 100
		)
	})

	const proportionGeneral = computed((): number => {
		return (netSalesGeneral.value * 100) / (netSubtotal.value * 100)
	})

	const proportionGrocery = computed((): number => {
		return (netSalesGrocery.value * 100) / (netSubtotal.value * 100)
	})

	const totalGeneralTax = computed((): number => {
		return Math.round(netSalesGeneral.value * 100 * taxRates.general) / 100
	})

	const totalGroceryTax = computed((): number => {
		return Math.round(netSalesGrocery.value * 100 * taxRates.grocery) / 100
	})

	const totalTax = computed((): number => {
		return (
			Math.round(
				totalGeneralTax.value * 100 + totalGroceryTax.value * 100,
			) / 100
		)
	})

	const grandTotal = computed((): number => {
		let subGrandTotal = netSubtotal.value * 100 + totalTax.value * 100
		if (deliveryMethod.value === "delivery")
			subGrandTotal += localDeliveryFee.value * 100
		if (deliveryMethod.value === "shipment")
			subGrandTotal += shippingCost.value * 100
		if (totalMillingFee.value > 0)
			subGrandTotal += totalMillingFee.value * 100
		return Math.round(subGrandTotal) / 100
	})

	const cashCertificateBalanceUsed = computed((): number => {
		if (cashCertificateBalance.value === 0) return 0
		else if (cashCertificateBalance.value > grandTotal.value)
			return grandTotal.value
		else return Math.round(cashCertificateBalance.value * 100) / 100
	})

	const balanceDue = computed((): number => {
		return (
			Math.round(
				grandTotal.value * 100 - cashCertificateBalanceUsed.value * 100,
			) / 100
		)
	})

	const getGiftCertificatePayments = computed(
		(): ICashCertificatePayment[] => {
			const payments = [] as ICashCertificatePayment[]

			if (cashCertificateBalanceUsed.value > 0) {
				if (cashCertificates.value.length === 1) {
					const payment: ICashCertificatePayment = {
						type: "giftcertificate",
						valueUsed: cashCertificateBalanceUsed.value,
						certificateID: cashCertificates.value[0].certificateID,
					}
					payments.push(payment)
				} else if (cashCertificates.value.length > 1) {
					cashCertificates.value.sort(
						(a, b) => a.currentValue - b.currentValue,
					)
					let remainingCertificateValueUsed =
						cashCertificateBalanceUsed.value
					for (const certificate of cashCertificates.value) {
						if (
							remainingCertificateValueUsed >
							certificate.currentValue
						) {
							const payment: ICashCertificatePayment = {
								type: "giftcertificate",
								valueUsed: certificate.currentValue,
								certificateID: certificate.certificateID,
							}
							remainingCertificateValueUsed =
								Math.round(
									remainingCertificateValueUsed * 100 -
										certificate.currentValue * 100,
								) / 100
							payments.push(payment)
						} else {
							const payment: ICashCertificatePayment = {
								type: "giftcertificate",
								valueUsed: remainingCertificateValueUsed,
								certificateID: certificate.certificateID,
							}
							payments.push(payment)
							break
						}
					}
				}
			}
			return payments
		},
	)

	const getReceiptData = (): IReceiptData => {
		return {
			// Gross Sales
			grossSales: {
				general: grossSalesGeneral.value,
				grocery: grossSalesGrocery.value,
				noTax: grossSalesNoTax.value,
			},
			grossSalesTotal: grossSubtotal.value,

			// Tax
			taxRates,
			tax: {
				general: totalGeneralTax.value,
				grocery: totalGroceryTax.value,
			},
			totalTax: totalTax.value,

			// Shipping
			shipping: finalShippingCostForSelectedDeliveryMethod.value,
			millingFee: totalMillingFee.value,

			// Discounts
			discounts: discounts.value,
			totalDiscount: totalDiscount.value,

			// Net Sales
			netSales: {
				general: netSalesGeneral.value,
				grocery: netSalesGrocery.value,
				noTax: netSalesNoTax.value,
			},
			netSalesTotal: netSubtotal.value,

			// GrandTotal
			grandTotal: grandTotal.value,
		}
	}

	const getCustomerProfile = (): ICustomerProfile => {
		const profile = {
			firstname: customerProfile.value.firstname,
			lastname: customerProfile.value.lastname,
			email: customerProfile.value.email,
			phone: customerProfile.value.phone,
		}
		return profile
	}

	const getOrderData = (): IOrderData => {
		const orderData: IOrderData = {
			source: "online",
			location: 1,
			items: cart.value,
			recipes: recipes.value,
			deliveryMethod: deliveryMethod.value ?? "pickup",
			needs_milling: cartNeedsMilling.value,
			millGrains: millGrains.value,
			specialInstructions: specialInstructions.value,

			customerID: 0,
			status: OrderStatus.PAYED,
		}
		if (deliveryMethod.value === "delivery") {
			orderData.delivery = deliveryAddress.value
		} else if (deliveryMethod.value === "shipment") {
			orderData.shipment = shippingAddress.value
		}
		return orderData
	}

	const addItemToCart = (
		inventoryItem: IPublicInventoryItem,
		newQuantity: number,
	) => {
		const cartItem = getItemInCartBySKU(inventoryItem.sku)
		if (cartItem === undefined) {
			const newCartItem: IShopCartItem = {
				...inventoryItem,
				quantity: newQuantity,
				netPrice: inventoryItem.price,
				discount: 0,
			}
			cart.value.push(newCartItem)
		} else {
			const updatedNewQuantity =
				Math.round((cartItem.quantity + newQuantity) * 100) / 100
			cartItem.quantity = updatedNewQuantity
		}
		//updateCart()
	}

	const updateItemInCart = (
		item: IShopCartItem | IPublicInventoryItem,
		quantity: number,
	): void => {
		const cartItemIndex = cart.value.findIndex((i) => i.sku === item.sku)
		if (cartItemIndex >= 0) {
			cart.value[cartItemIndex].quantity = quantity
			updateCart()
		} else {
			addItemToCart(item, quantity)
		}
	}

	const removeItemInCart = (sku: number) => {
		const index = cart.value.findIndex((cartItem) => cartItem.sku === sku)
		if (index >= 0) {
			cart.value.splice(index, 1)
			updateCart()
		}
	}

	const clearCart = () => {
		cart.value = []
		recipes.value = []
		updateCart()
	}

	const addRecipe = (newRecipe: IRecipe) => {
		if (recipes.value.length === 0) {
			newRecipe.id = 1
		} else {
			const lastID = recipes.value[recipes.value.length - 1].id
			newRecipe.id = lastID + 1
		}
		recipes.value.push(newRecipe)
		updateCart()
	}

	const updateRecipe = (updatedRecipe: IRecipe) => {
		const recipeID = updatedRecipe.id
		const recipe = recipes.value.find((i) => i.id === recipeID)
		if (recipe) {
			const index = recipes.value.indexOf(recipe)
			recipes.value[index] = updatedRecipe
			updateCart()
		} else {
			throw new Error("Recipe not found", { cause: recipeID })
		}
	}

	const removeItemInRecipe = (recipeID: number, sku: number) => {
		const recipe = recipes.value.find((i) => i.id === recipeID)
		if (!recipe) return
		const recipeItem = recipe.items.find((i) => i.sku === sku)
		if (!recipeItem) return
		const index = recipe.items.indexOf(recipeItem)
		recipe.items.splice(index, 1)
		updateCart()
	}

	const removeRecipe = (recipeID: number) => {
		const recipe = recipes.value.find((i) => i.id === recipeID)
		if (!recipe) return
		const index = recipes.value.indexOf(recipe)
		recipes.value.splice(index, 1)
		updateCart()
	}

	const addCashCertificate = (certificate: ICashCertificate) => {
		cashCertificates.value.push(certificate)
	}

	const removeCashCertificate = (certificateID: string) => {
		const index = cashCertificates.value.findIndex(
			(certificate) => certificate.certificateID === certificateID,
		)
		cashCertificates.value.splice(index, 1)
	}

	const addDiscount = (discount: IDiscount) => {
		if (
			!discounts.value.some(
				(activeDiscount) => activeDiscount.code === discount.code,
			)
		) {
			discounts.value.push(discount)

			updateCart()
		}
	}

	const removeDiscount = (discountCode: IDiscount["code"]) => {
		const index = discounts.value.findIndex(
			(discount) => discount.code === discountCode,
		)
		discounts.value.splice(index, 1)
		updateCart()
	}

	// Each time an item or discount is added, it could affect the netPrice
	const updateCart = () => {
		const discountedCart = new Set(cart.value)
		recipes.value.forEach((recipe) => {
			recipe.items.forEach((item) => {
				discountedCart.add(item)
			})
		})
		discountedCart.forEach((item) => {
			item.discount = 0
			const netPrice = item.price * 100
			item.netPrice = netPrice
		})

		const discountsCopy = cloneDeep(discounts.value)
		const pipe = constructDiscountPipe(discountsCopy)
		const arrayCart = Array.from(discountedCart)
		const updatedCart = new Set(pipe(arrayCart))
		updatedCart.forEach((item) => {
			item.netPrice = Math.round(item.netPrice) / 100
			item.discount = Math.round(item.discount) / 100
		})
	}

	const resetOrder = () => {
		window.localStorage.removeItem("saved-order")
		cart.value = []
		recipes.value = []
		customerProfile.value = {
			firstname: "",
			lastname: "",
			email: "",
			phone: "",
		}
		deliveryMethod.value = "pickup"
		deliveryAddress.value = {
			token: "",
			firstname: "",
			lastname: "",
			address1: "",
			address2: "",
			city: "",
			state: "",
			zipcode: "",
			phone: "",
			deliveryInstructions: "",
		}
		shippingAddress.value = {
			token: "",
			firstname: "",
			lastname: "",
			address1: "",
			address2: "",
			city: "",
			state: "",
			zipcode: "",
		}
		cashCertificates.value = []
		discounts.value = []
		millGrains.value = false
		specialInstructions.value = ""
		filterCategory.value = "Clearance"
	}

	const itemCount = computed((): number => {
		return cart.value.length
	})

	return {
		cart,
		recipes,
		cartNeedsMilling,
		itemsThatNeedMilling,
		millCount,
		totalMillingFee,
		doesDiscountApply,
		itemsThatWontShip,
		canShip,
		itemsThatShipFree,
		itemsThatDontShipFree,
		subtotalOfItemsThatShipFree,
		freeShippingEligible,
		flatRateShippingEligible,
		shippingCost,
		finalShippingCostForSelectedDeliveryMethod,
		getRecipeByID,
		getNextRecipeID,
		getItemInRecipe,
		getItemInCartBySKU,
		getQuantityOrderedElsewhere,
		cashCertificateBalance,
		grossSalesGeneral,
		grossSalesGrocery,
		grossSalesNoTax,
		grossSubtotal,
		totalDiscount,
		netSalesGeneral,
		netSalesGrocery,
		netSalesNoTax,
		netSubtotal,
		proportionGeneral,
		proportionGrocery,
		totalGeneralTax,
		totalGroceryTax,
		totalTax,
		grandTotal,
		cashCertificateBalanceUsed,
		balanceDue,
		getGiftCertificatePayments,
		getReceiptData,
		getCustomerProfile,
		getOrderData,
		addItemToCart,
		updateItemInCart,
		removeItemInCart,
		clearCart,
		addRecipe,
		updateRecipe,
		removeItemInRecipe,
		removeRecipe,
		addCashCertificate,
		removeCashCertificate,
		addDiscount,
		removeDiscount,
		updateCart,
		resetOrder,
		localDeliveryzipcodes,
		deliveryMethod,
		deliveryAddress,
		shippingAddress,
		customerProfile,
		cashCertificates,
		discounts,
		millGrains,
		specialInstructions,
		filterCategory,
		sortCategories,
		itemCount,
	}
})
