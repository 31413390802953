import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "billboard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "billboard-textbox" }, [
      _createElementVNode("h1", { class: "center" }, "Sorry, we couldn't find the page you're looking for.")
    ], -1 /* HOISTED */),
    _createElementVNode("div", { class: "billboard-imagebox" }, [
      _createElementVNode("img", { src: "https://gnomebrew-cdn.s3.us-east-2.amazonaws.com/images/logos/gnome-brew-logo-with-text-full-color.png" })
    ], -1 /* HOISTED */)
  ])))
}