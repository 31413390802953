import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "header-cart" }
const _hoisted_2 = { id: "header-cart-count" }
const _hoisted_3 = { id: "header-cart-contents" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button_Medium = _resolveComponent("Button_Medium")!
  const _component_ButtonBar = _resolveComponent("ButtonBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "headertoolbar-buttonbox",
      id: "header-cart-icon",
      onClick: $setup.toggleOrderBagOpen
    }, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "headertoolbar-buttonbox-text" }, "Bag", -1 /* HOISTED */)),
      _cache[1] || (_cache[1] = _createElementVNode("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        height: "48",
        width: "48"
      }, [
        _createElementVNode("path", {
          fill: "#FFF",
          d: "M11.9 43q-1.2 0-2.05-.85Q9 41.3 9 40.1V15.9q0-1.2.85-2.05.85-.85 2.05-.85h4.95v-.85q0-3 2.075-5.075T24 5q3 0 5.075 2.075t2.075 5.075V13h4.95q1.2 0 2.05.85.85.85.85 2.05v24.2q0 1.2-.85 2.05-.85.85-2.05.85Zm0-2.25h24.2q.25 0 .45-.2t.2-.45V15.9q0-.25-.2-.45t-.45-.2h-4.95v4.9q0 .45-.325.775T30 21.25q-.5 0-.825-.325-.325-.325-.325-.775v-4.9h-9.7v4.9q0 .45-.325.775T18 21.25q-.5 0-.825-.325-.325-.325-.325-.775v-4.9H11.9q-.25 0-.45.2t-.2.45v24.2q0 .25.2.45t.45.2ZM19.15 13h9.7v-.85q0-2.05-1.4-3.475Q26.05 7.25 24 7.25q-2.05 0-3.45 1.425-1.4 1.425-1.4 3.475Zm-7.9 27.75V15.25 40.75Z"
        })
      ], -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_2, _toDisplayString(($setup.itemCount ? $setup.itemCount : "")), 1 /* TEXT */)
    ]),
    _createElementVNode("div", {
      id: "header-cart-wrapper",
      class: _normalizeClass({open: $setup.isCartOpen})
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["Cart"], {
          editmode: true,
          onCloseHeaderCart: $setup.closeHeaderCart
        })
      ]),
      _createElementVNode("div", {
        id: "header-cart-footer",
        class: _normalizeClass({open: $setup.isCartOpen})
      }, [
        _createVNode(_component_ButtonBar, null, {
          default: _withCtx(() => [
            _createVNode(_component_Button_Medium, { onClick: $setup.checkout }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Checkout")
              ])),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_Button_Medium, { onClick: $setup.clearCart }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Clear")
              ])),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ], 2 /* CLASS */)
    ], 2 /* CLASS */)
  ]))
}