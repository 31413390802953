import { defineComponent as _defineComponent } from 'vue'
import type { ISearchItem } from "@shared/composables/useSearch"
import SearchItem from "./QuickAdd_ResultItem.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'QuickAdd_ResultList',
  props: {
    results: {},
    selectedResultIndex: {}
  },
  emits: ["select"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit
const props = __props

const isSelected = (index: number) => {
	return index === props.selectedResultIndex
}

const __returned__ = { emit, props, isSelected, SearchItem }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})