import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeBuilder_SearchBox',
  props: /*@__PURE__*/_mergeModels({
    isSearchLoading: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose }) {
  __expose();


const searchTerm = _useModel<string>(__props, "modelValue")

const __returned__ = { searchTerm }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})