import { defineComponent as _defineComponent } from 'vue'
import { logger as $log } from "@Plugins/clientLogger"
import { useInventoryItemsSearch } from "@shared/composables/useSearch"
import { useQuery } from "@tanstack/vue-query"
import {
	getRecipeBuilderItems,
	getRecipeBuilderSearchIndex,
} from "@website/api/inventory"
import { useRecipeBuilderStore } from "@website/stores/recipeBuilder"
import { computed, ref, watch } from "vue"
import QuickAdd_ResultList from "./QuickAdd_ResultList.vue"
import RecipeBuilder_SearchBox from "./RecipeBuilder_SearchBox.vue"

import { useModal } from "@shared/plugins/gbModal/gbModal"
import { debounce } from "lodash"
import type { IPublicInventoryItem } from "types/InventoryItem"


export default /*@__PURE__*/_defineComponent({
  __name: 'QuickAdd',
  emits: ["closeHeaderMenu"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit

const recipeBuilderStore = useRecipeBuilderStore()
const { data: recipeBuilderInventory, status: inventoryQueryStatus } = useQuery<
	IPublicInventoryItem[]
>({
	queryKey: ["recipeBuilderInventoryItems"],
	queryFn: getRecipeBuilderItems,
})
const isSearchLoading = computed<boolean>(() => {
	return inventoryQueryStatus.value !== "success" || ready.value !== true
})
const { data: recipeBuilderSearchIndex } = useQuery({
	queryKey: ["recipeBuilderSearchIndex"],
	queryFn: getRecipeBuilderSearchIndex,
})

const searchTerm = ref("")
watch(searchTerm, (newSearchTerm) => {
	recipeBuilderStore.searchTermForLogging = newSearchTerm
	if (newSearchTerm.length === 0) return
	debouncedSearchLogger(newSearchTerm, results.value.length)
})
const debouncedSearchLogger = debounce(
	(searchTerm: string, resultsCount: number) => {
		$log.info("Quick Add Search", { searchTerm, resultsCount })
	},
	500,
)

const { results, ready } = useInventoryItemsSearch(
	recipeBuilderInventory,
	recipeBuilderSearchIndex,
	searchTerm,
)

const clickListenerActive = ref(false)
const touchListenerActive = ref(false)
watch(results, () => {
	if (results.value.length > 0) {
		if (!clickListenerActive.value)
			window.addEventListener("click", checkForClickToClose)
		if (!touchListenerActive.value)
			window.addEventListener("touchend", checkForClickToClose)
	}
})

const selectedResultIndex = ref(0)
const resultCount = computed(() => results.value.length)
const handleUp = () => {
	if (selectedResultIndex.value > 0) selectedResultIndex.value--
}
const handleDown = () => {
	if (selectedResultIndex.value < resultCount.value - 1)
		selectedResultIndex.value++
}
const activeResult = computed(() => results.value[selectedResultIndex.value])
const handleSelectResult = (sku: number, resultRank: number) => {
	const { success, errorMessage } = recipeBuilderStore.updateWorkingRecipe(
		sku,
		1,
		resultRank,
		"QuickAdd",
	)
	if (success === false) useModal.show("gbDialog", { title: errorMessage })
	else {
		resetSearch()
	}
}
const selectResult = () => {
	if (activeResult.value) {
		handleSelectResult(
			activeResult.value.item.sku,
			selectedResultIndex.value,
		)
	}
}

const resetSearch = () => {
	searchTerm.value = ""
	selectedResultIndex.value = 0
	window.removeEventListener("touchend", checkForClickToClose)
	window.removeEventListener("click", checkForClickToClose)
}

const checkForClickToClose = (event: Event): void => {
	const searchArea = document.getElementById("search")
	if (
		searchArea &&
		event.target instanceof Node &&
		!searchArea.contains(event.target)
	)
		resetSearch()
}

const __returned__ = { emit, recipeBuilderStore, recipeBuilderInventory, inventoryQueryStatus, isSearchLoading, recipeBuilderSearchIndex, searchTerm, debouncedSearchLogger, results, ready, clickListenerActive, touchListenerActive, selectedResultIndex, resultCount, handleUp, handleDown, activeResult, handleSelectResult, selectResult, resetSearch, checkForClickToClose, get $log() { return $log }, get useInventoryItemsSearch() { return useInventoryItemsSearch }, get useQuery() { return useQuery }, get getRecipeBuilderItems() { return getRecipeBuilderItems }, get getRecipeBuilderSearchIndex() { return getRecipeBuilderSearchIndex }, get useRecipeBuilderStore() { return useRecipeBuilderStore }, computed, ref, watch, QuickAdd_ResultList, RecipeBuilder_SearchBox, get useModal() { return useModal }, get debounce() { return debounce } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})