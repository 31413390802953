import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "headertoolbar-buttonbox-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("span", null, [
    ($setup.isLoggedIn)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          class: "headertoolbar-buttonbox",
          to: {path: '/account'}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, "Hello, " + _toDisplayString($setup.profile?.firstname), 1 /* TEXT */),
            _cache[0] || (_cache[0] = _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              height: "48",
              width: "48"
            }, [
              _createElementVNode("path", {
                fill: "#FFF",
                d: "M9.25 37.15q2.95-2.75 6.675-4.425Q19.65 31.05 24 31.05t8.075 1.675Q35.8 34.4 38.75 37.15V9.9q0-.25-.2-.45t-.45-.2H9.9q-.25 0-.45.2t-.2.45Zm14.8-10.35q2.7 0 4.575-1.875T30.5 20.35q0-2.7-1.875-4.575T24.05 13.9q-2.7 0-4.55 1.875-1.85 1.875-1.85 4.575t1.85 4.575q1.85 1.875 4.55 1.875ZM9.9 41q-1.2 0-2.05-.85Q7 39.3 7 38.1V9.9q0-1.2.85-2.05Q8.7 7 9.9 7h28.2q1.2 0 2.05.85.85.85.85 2.05v28.2q0 1.2-.85 2.05-.85.85-2.05.85Zm1.35-2.25h25.5v-.4q-2.8-2.5-6.025-3.775Q27.5 33.3 24 33.3q-3.45 0-6.7 1.25t-6.05 3.75v.45ZM24.1 24.5q-1.75 0-2.975-1.2T19.9 20.35q0-1.75 1.225-2.95 1.225-1.2 2.925-1.2 1.75 0 2.975 1.225t1.225 2.925q0 1.75-1.225 2.95-1.225 1.2-2.925 1.2Zm-.1-1.3Z"
              })
            ], -1 /* HOISTED */))
          ]),
          _: 1 /* STABLE */
        }))
      : (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          class: "headertoolbar-buttonbox",
          to: "/login"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("div", { class: "headertoolbar-buttonbox-text" }, "Login", -1 /* HOISTED */),
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              height: "48",
              width: "48"
            }, [
              _createElementVNode("path", {
                fill: "#FFF",
                d: "M9.25 37.15q2.95-2.75 6.675-4.425Q19.65 31.05 24 31.05t8.075 1.675Q35.8 34.4 38.75 37.15V9.9q0-.25-.2-.45t-.45-.2H9.9q-.25 0-.45.2t-.2.45Zm14.8-10.35q2.7 0 4.575-1.875T30.5 20.35q0-2.7-1.875-4.575T24.05 13.9q-2.7 0-4.55 1.875-1.85 1.875-1.85 4.575t1.85 4.575q1.85 1.875 4.55 1.875ZM9.9 41q-1.2 0-2.05-.85Q7 39.3 7 38.1V9.9q0-1.2.85-2.05Q8.7 7 9.9 7h28.2q1.2 0 2.05.85.85.85.85 2.05v28.2q0 1.2-.85 2.05-.85.85-2.05.85Zm1.35-2.25h25.5v-.4q-2.8-2.5-6.025-3.775Q27.5 33.3 24 33.3q-3.45 0-6.7 1.25t-6.05 3.75v.45ZM24.1 24.5q-1.75 0-2.975-1.2T19.9 20.35q0-1.75 1.225-2.95 1.225-1.2 2.925-1.2 1.75 0 2.975 1.225t1.225 2.925q0 1.75-1.225 2.95-1.225 1.2-2.925 1.2Zm-.1-1.3Z"
              })
            ], -1 /* HOISTED */)
          ])),
          _: 1 /* STABLE */
        }))
  ]))
}