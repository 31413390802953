import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, ref, watch } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'drawer',
  props: {
    startDrawerOpen: { type: Boolean, default: true },
    alwaysOpen: { type: Boolean, default: false },
    showDrawer: { type: Boolean, default: true },
    showHeader: { type: Boolean, default: true },
    header: { default: "" },
    mobileCollapse: { type: Boolean, default: false },
    maxHeight: { default: "1500px" },
    resizable: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false },
    scroll: { type: Boolean, default: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

_useCssVars(_ctx => ({
  "c4efeab4": (_ctx.maxHeight),
  "0d9e65a7": (overflow.value)
}))

const props = __props

const isDrawerOpen = ref(false)
const overflow = computed((): string => (props.scroll ? "auto" : "hidden"))

onMounted((): void => {
	isDrawerOpen.value =
		props.startDrawerOpen &&
		(!isSmallScreen.value || props.mobileCollapse == false)
})

const classesArray = computed((): string[] => {
	let classes: string[] = []
	if (props.resizable) classes.push("resizable")
	return classes
})

const drawerToggleActive = computed((): boolean => {
	if (props.alwaysOpen === false) return true
	else if (props.mobileCollapse && isSmallScreen.value) return true
	else return false
})

const isSmallScreen = computed((): boolean => {
	const breakWidth = 600
	if (window.innerWidth < breakWidth) {
		return true
	} else {
		return false
	}
})

const toggleDrawerOpen = (): void => {
	if (props.alwaysOpen) {
		//AlwaysOpen on Desktop  - Do nothing
		if (!isSmallScreen.value) return
		//AlwaysOpen on Mobile Without MobileCollapse - Do Nothing
		else if (!props.mobileCollapse) return
		//Always Open on Mobile with Mobile Collapse
		else isDrawerOpen.value = !isDrawerOpen.value
	} else {
		isDrawerOpen.value = !isDrawerOpen.value
	}
}

watch(
	() => props.showDrawer,
	(newValue) => {
		if (props.alwaysOpen) {
			//AlwaysOpen on Desktop  - Do nothing
			if (!isSmallScreen.value) return
			//AlwaysOpen on Mobile Without MobileCollapse - Do Nothing
			else if (!props.mobileCollapse) return
			//Always Open on Mobile with Mobile Collapse
			else isDrawerOpen.value = newValue
		} else {
			isDrawerOpen.value = newValue
		}
	},
)

const __returned__ = { props, isDrawerOpen, overflow, classesArray, drawerToggleActive, isSmallScreen, toggleDrawerOpen, computed, onMounted, ref, watch }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})