import { defineComponent as _defineComponent } from 'vue'
import { useModal } from "@Plugins/gbModal/gbModal"
import { onMounted, ref, watch } from "vue"
import { useRouter } from "vue-router"


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeName.gbModal',
  props: {
    recipeName: {},
    isNewRecipe: { type: Boolean }
  },
  emits: ["saveRecipeName", "cancelNewRecipe"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props
const emit = __emit

const workingRecipeName = ref()
const nameInput = ref<HTMLInputElement | null>(null)

watch(
	() => nameInput.value,
	() => {
		if (nameInput.value instanceof HTMLInputElement) {
			nameInput.value.focus()
			nameInput.value.select()
		}
	},
	{ immediate: true },
)

onMounted(() => {
	workingRecipeName.value = structuredClone(props.recipeName)
})

const opened = (): void => {
	workingRecipeName.value = structuredClone(props.recipeName)
}

const updateRecipeName = (): void => {
	emit("saveRecipeName", workingRecipeName.value)
	useModal.hide("newRecipeDialog")
}

const cancelNewRecipe = (): void => {
	emit("cancelNewRecipe")
	useModal.hide("newRecipeDialog")
}

const cancelRenameRecipe = (): void => {
	useModal.hide("newRecipeDialog")
}

const router = useRouter()
const gotoAboutRecipeBuilder = (): void => {
	void router.push("")
}

const __returned__ = { props, emit, workingRecipeName, nameInput, opened, updateRecipeName, cancelNewRecipe, cancelRenameRecipe, router, gotoAboutRecipeBuilder, get useModal() { return useModal }, onMounted, ref, watch, get useRouter() { return useRouter } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})